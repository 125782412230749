.<template>
  <div>
    <!-- pc -->
    <div class="home">
      <el-backtop :right="40" :bottom="150" id="backTopBtn" @click="backTopBtn" />
      <div :class="{ mask: isShow }"></div>
      <!-- <transition name="fade"> -->
      <!-- <div class="isShow" v-show="true" ref="boxShow">
        </div> -->
      <!-- </transition> -->
      <!-- 内容 -->
      <transition name="test">
        <div class="content" v-show="isVanish">
          <div class="header">
            <div class="left">
              <img src="@/assets/homePage/logo.svg" @click="route.push('/')" />
            </div>
            <div class="center">
              <div v-for="item in menuList" style="overflow: hidden;" @click="RoutePus(item.id)" class="center_1"
                :key="item.id">
                {{ item.title }}
              </div>
            </div>
            <div class="right">
              <lang class="lang"></lang>
            </div>
          </div>
        </div>
      </transition>
      <!-- 轮播图 -->
      <el-carousel :interval="3000">
        <el-carousel-item v-for="item in carouselList" :key="item.id">
          <img :src="item.src" class="images" />
        </el-carousel-item>
        <!-- <el-carousel-item v-for="item in 4" :key="item">
        <h3 text="2xl" justify="center">{{ item }}</h3>
      </el-carousel-item> -->
      </el-carousel>

      <h2 class="h1_1">新闻与活动</h2>
      <!-- 中间内容区域 -->
      <div class="news">
        <div class="content_01" v-for="item in imgDataList11111" :key="item.id" @click="routePush(item.id)">
          <div class="box_left">展会活动</div>
          <img :src="item.src" alt="">
          <div class="box">
            <h3 style="margin: 0;padding:0;">{{ item.name_1 }}</h3>
            <p class="span01">{{ item.name_2 }}</p>
          </div>
        </div>
        <div class="content_02">
          <div class="box_left">新闻</div>
          <p v-for="item in pList" :key="item.id" class="box_p2" @click="routePush(item.queId)">
          <h4>{{ item.title }}</h4><br />
          <p class="box_p" style="color:#aaa;">{{ item.date }}</p>
          </p>
        </div>
      </div>
      <div class="info">
        <div class="info_1">
          <p @click="$router.push('/zh/news')">更多新闻</p>
          <p @click="$router.push('/zh/news')">更多活动</p>
        </div>
      </div>
      <div class="videos">
         <img src="@/assets/homePage/视频banner.png" style="width: 100%;" @click="$router.push({path:'/zh/news',query:{id:2}})">
      </div>
      <h2 class="h1_1">更多信息</h2>
      <div class="more">
        <div class="box">
          <div class="box_1" @click="$router.push('/zh/quality')">
            <div class="show"></div>
            <img src="@/assets/homePage/图片3.png" alt="">
            <div class="son_box">
              <h5>盛泰品质中心</h5>
              <p>盛泰多维度把握品质大关，始终坚持品质至上，追求卓越</p>
              <div>了解更多<span style="color:red;">
                  <el-icon>
                    <ArrowRight />
                  </el-icon>
                </span></div>
            </div>
          </div>
          <div class="box_2" @click="$router.push('/zh/idea')">
            <div class="show"></div>
            <img src="@/assets/homePage/图片4.png" alt="">
            <div class="son_box">
              <h5>盛泰人才理念观</h5>
              <p>盛泰主张因材施教，因地制宜，科学搭建人才发展平台</p>
              <div>了解更多<span style="color:red;">
                  <el-icon>
                    <ArrowRight />
                  </el-icon>
                </span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="more" style="margin-top: 30px;">
        <div class="box" style="flex-direction:row-reverse;">
          <div class="box_1" @click="routePush(1)">
            <div class="show"></div>
            <img src="@/assets/homePage/图片6.png" alt="">
            <div class="son_box">
              <h5>盛泰光电何阿强上榜“最美青年技能人才”</h5>
              <p>何阿强同志上榜大足区“最美青年”优秀事迹</p>
              <div>了解更多<span style="color:red;">
                  <el-icon>
                    <ArrowRight />
                  </el-icon>
                </span></div>
            </div>
          </div>
          <div class="box_2" @click="$router.push('/zh/develop')">
            <div class="show"></div>
            <img src="@/assets/homePage/图片5.png" alt="">
            <div class="son_box">
              <h5>致力可持续发展</h5>
              <p>盛泰一直致力于科技与环境共生</p>
              <div>了解更多<span style="color:red;">
                  <el-icon>
                    <ArrowRight />
                  </el-icon>
                </span></div>
            </div>
          </div>

        </div>
      </div>
      <!-- {{t('state.product')}} -->
      <foot></foot>
      <!-- <el-backtop :right="100" :bottom="100" @click="backtopBtn" /> -->
    </div>
    <!-- 小屏 -->
    <div class="_div">
      <!-- 顶部 -->
      <move_header></move_header>
      <!-- 轮播图 -->
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in carouselList" :key="item.id">
          <img :src="item.src" style="width: 100%;">
        </van-swipe-item>
      </van-swipe>
      <!-- 中间内容 -->
      <h2>新闻与活动</h2>
      <div class="activity">
        <div class="activity_1" v-for="item in imgDataList11111" :key="item.id" @click="routePush(item.id)">
          <img :src="item.src" alt="">
          <div class="box_left">展会活动</div>
          <div class="box">
            <h3 style="margin: 0;padding:0;">{{ item.name_1 }}</h3>
            <p class="span01">{{ item.name_2 }}</p>
          </div>
        </div>
      </div>
      <div class="jour">
        <div class="box_left">新闻</div>
        <p v-for="item in pList" :key="item.id" @click="$router.push(routePush(item.queId))">
          {{ item.title }}<br />
          <span style="color: #333;font-weight: 400;">{{ item.date }}</span>
        </p>
      </div>
      <div class="moreJour">
        <p @click="$router.push('/zh/news')">更多新闻</p>
        <p @click="$router.push('/zh/news')">更多活动</p>
      </div>

      <div class="videos_move">
         <img src="@/assets/homePage/视频banner.png" style="width: 100%;cursor: pointer;" @click="$router.push({path:'/zh/news',query:{id:2}})">
      </div>
      <h2>更多信息</h2>
      <div class="move_img1" v-for="item in move_img_List" :key="item.id" @click="move_Btn(item.id)">
        <img :src="item.url" alt="">
        <div class="box">
          <p> {{ item.title_1 }}</p>
          <p style="font-size: 2.5rem;"> {{ item.title_2 }}</p>
        </div>
      </div>
      <div class="move_img2">
        <div class="Move" v-for="item in move_img2" :key="item.id" @click="move_img2Btn(item.id)">
          <img :src="item.url" alt="">
          <div class="box_11">
            <p> {{ item.title_1 }}</p>
            <p style="font-size: 2.5rem;"> {{ item.title_2 }}</p>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <move_foot></move_foot>
    </div>

  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import lang from "@/component/lang.vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import foot from "../components/foot.vue";
import move_header from "../move_components/move_header.vue";
import move_foot from "../move_components/move_foot.vue";
import video_1 from '@/component/video_1.vue'
import video_2 from '@/component/video_2.vue'
export default {
  components: {
    move_header,
    move_foot,
    lang,
    foot,
    video_1,
    video_2
  },
  setup() {
    const route = useRouter();
    const { t } = useI18n();
    const boxShow = ref();
    const state = reactive({
      isVanish: true, //控制导航栏显示
      isShow: false, //控制隐藏盒子
      id: 0,
      carousel: 968,
      move_img_List: [
        { id: 0, url: require('@/assets/homePage/图片3.png'), title_1: '盛泰品质中心', title_2: '盛泰多维度把握品质大关,始终坚持品质至上追求卓越' },
        { id: 1, url: require('@/assets/homePage/图片6.png'), title_1: '盛泰光电何阿强上榜“最美青年技能人才”', title_2: '何阿强同志上榜大足区“最美青年”优秀事迹' },
      ],
      move_img2: [
        { id: 1, url: require('@/assets/homePage/图片4.png'), title_1: '盛泰人才理念观', title_2: '盛泰主张因材施教，因地制宜，科学搭建人才发展平台' },
        { id: 2, url: require('@/assets/homePage/图片5.png'), title_1: '致力于可持续发展', title_2: '盛泰一直致力于科技与环境共生' },
      ],
      menuList: [
        { id: 1, title: '产品中心' },
        { id: 2, title: '关于盛泰' },
        { id: 3, title: '新闻资讯' },
        { id: 4, title: '合作与发展'},
        { id: 5, title: '加入我们' },
        { id: 6, title: '联系我们' },
      ],
      // p标签
      pList: [
        {id:134,date:"2024年12月2日",title:"喜讯|盛泰光电荣获大足区2023年度科技型企业研发投入第二名",url: require('@/assets/news/小图/134.png'), queId: 134},
        {id:133,date:"2024年12月2日",title:"喜讯|盛泰光电荣获“双百企业”奖项",url: require('@/assets/news/小图/133.png'), queId: 133},
        {id:132,date:"2024年11月27日",title:"大足区委组织部（老干部局）组织离退休干部到盛泰光电参观调研",url: require('@/assets/news/小图/132.png'), queId: 132},
        {id:131,date:"2024年11月25日",title:"同心同行同成长，携手共进创未来|人力资源部举行2024年第三期IDL座谈会",url: require('@/assets/news/小图/131.png'), queId: 131},
      ],
      // 图片的数组
      imgDataList: [],
      imgDataList11111: [
        {
          id: 8,
          src: require("@/assets/homePage/图片1.png"),
          name_1: "做大做强产业集群",
          name_2: '区领导到双桥经开区调研重点企业检查安全生产'
        },
        {
          id: 5,
          src: require("@/assets/homePage/图片2.png"),
          name_1: "公司获得重庆民营、制造双百强",
          name_2: "2021重庆民营企业100强峰会",
        },
      ],
      // 轮播图的数组
      carouselList: [
        { id: 4, src: require("@/assets/homePage/4.png") },
        { id: 2, src: require("@/assets/homePage/2.png") },
        { id: 3, src: require("@/assets/homePage/3.png") },
        { id: 1, src: require("@/assets/homePage/1.png") },
      ],
    });
    let methods = {
      routePush(id) {
        const href = route.resolve({ //使用resolve
          name: 'zhNewsId',    //这里是跳转页面的name
          path: '/zh/newsId',
          query: { id }
        })
        window.open(href.href, '_blank')
      },
      RoutePus(id) {
        // console.log(route);
        localStorage.setItem('menuId', id)
        switch (id) {
          case 1:
            route.push("/zh/product");
            break;
          case 2:
            route.push("/zh/about");
            break;
          case 3:
            route.push("/zh/news");
            break;
          case 4:
            route.push("/zh/cooperation");
            break;
          case 5:
            route.push("/zh/join");
            break;
          case 6:
            route.push("/zh/contactus");
            break;
          default:
        }
      },
      handleScroll(val) {
        if (val.deltaY > 0) {
          // 向下滚动
          state.isVanish = false;
        } else {
          // 向上滚动
          state.isVanish = true;
        }
        if (state.isShow) {
          state.isVanish = true;
        }
      },
      move_Btn(id) {
        if (id == 0) {
          route.push('/zh/quality')
        } else {
          const href = route.resolve({ //使用resolve
            name: 'zhNewsId',    //这里是跳转页面的name
            path: '/zh/newsId',
            query: { id }
          })
          window.open(href.href, '_blank')
        }
      },
      move_img2Btn(id) {
        if (id == 1) {
          route.push('/zh/idea')
        } else if (id == 2) {
          route.push('/zh/develop')
        }
      },
      animateButton(e) {
        e.preventDefault;
        //reset animation
        e.target.classList.remove("animate");

        e.target.classList.add("animate");
        setTimeout(function () {
          e.target.classList.remove("animate");
        }, 700);
      },
      add() {
        var className = document.getElementsByClassName("bubbly-button");

        for (var i = 0; i < className.length; i++) {
          className[i].addEventListener("click", methods.animateButton, false);
        }
      },
      onmouseover(id) {
        state.id = id;
        state.isShow = true;
      },
      onmouseout() {
        state.isShow = false;
      },
      backTopBtn() {
        // console.log(11111);
        state.isVanish = true;
      },
      boxMouseover() {
        state.isShow = true;
      },
      boxMouseout() {
        state.isShow = false;
      },
    };
    onMounted(() => {
      window.addEventListener("mousewheel", methods.handleScroll);
    });
    return {
      ...toRefs(state),
      ...methods,
      boxShow,
      route,
      t,
    };
  },
};
</script>

<style scoped lang="less">
.box_p {
  margin-top: -20px;
}

.box_left {
  position: absolute;
  left: 0;
  top: 0;
  width: 120px;
  height: 45px;
  background-color: #aaa;
  line-height: 45px;
  text-align: center;
  color: #fff;
  z-index: 3;
}

.more {
  margin: 0 auto;
  max-width: 1920px;
  height: auto;
  width: 70%;

  .box {
    width: 100%;
    display: flex;
    justify-content: space-around;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      transition: all 0.5s;
    }

    .box_1:hover img,
    .box_2:hover img {
      transform: scale(1.03);
      transition: all 0.5s;
    }

    .box_1:hover .son_box,
    .box_2:hover .son_box {
      bottom: 0;
    }

    .box_1::before,
    .box_2::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: rgba(0,0,0,.2) no-repeat center;
      background: linear-gradient(to top, #000 20%, transparent 70%);
      opacity: 0.8;
      display: none;
      z-index: 9;
    }

    .box_1:hover::before,
    .box_2:hover::before {
      display: block;
    }

    .box_1 {
      width: 63%;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      height: 333px;


    }

    .box_2 {
      width: 33%;
      height: 333px;
      position: relative;
      cursor: pointer;
      overflow: hidden;

    }

    .son_box {
      position: absolute;
      bottom: -30px;
      width: 90%;
      height: 130px;
      background: linear-gradient(to right, #1D469A, transparent);
      font-size: 25px;
      color: #ccc;
      transition: all 0.5s;
      padding-left: 30px;
      z-index: 99;

      h5 {
        margin: 10px 0;
        padding: 0;
        color: #fff;

      }

      p {
        font-size: 16px;
        color: #fff;

      }

      div:hover {
        color: red;
      }

      div {
        position: relative;
        font-size: 16px;
        margin-top: 25px;
        width: 100px;

        span {
          position: absolute;
          top: 0px;
          display: inline-block;
        }
      }
    }
  }
}

.videos {
  width: 69%;
  height: auto;
  margin: 0 auto;
  cursor: pointer;
}

.info {
  margin: 0 auto;
  max-width: 1920px;
  height: auto;
  width: 40%;
  display: flex;

  .info_1 {
    margin: 0 auto;
  }

  p {
    flex: 1;
    text-align: center;
    margin: 40px 20px;
    border: 1px solid #ccc;
    height: 47px;
    line-height: 47px;
    width: 150px;
    cursor: pointer;
    float: left;
    overflow: hidden;
  }

  p:hover {
    background-color: #ef3e2e;
    color: #fff;
  }
}

.news {
  margin: 0 auto;
  max-width: 1920px;
  height: auto;
  width: 70%;
  display: flex;
  box-sizing: border-box;

  .content_02 {
    position: relative;
    padding: 50px 20px 0 28px;
    overflow-y: auto;
    background-color: #F8F8F8;
    box-sizing: border-box;

    .box {
      font-size: 16px;
    }

    .box_p2 {
      margin: 10px 0;
    }

    h4 {
      margin: 0;
      padding: 0px 0;
      font-size: 18px;
    }

    p:hover {
      color: #ef3e2e;
      cursor: pointer;
    }
  }

  .content_01,
  .content_02 {
    margin: 0 auto;
    max-width: 30%;
    min-width: 30%;
    height: 400px;
  }

  .content_01 {
    position: relative;
    transition: all 0.5s;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      transition: all 0.5s;
    }

    .box {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 120px;
      background: linear-gradient(to right, #1D469A, transparent);
      font-size: 20px;
      color: #ccc;
      line-height: 80px;
      transition: all 0.5s;
      padding-left: 30px;

      .span01 {
        height: 60px;
        z-index: 9;
        margin-top: -40px;
        font-size: 15px;
      }
    }

    .pBox {
      padding: 25px 28px 36px;
    }
  }

  .content_01:hover .box {
    padding-left: 50px;
    transition: all 0.5s;
    color: #fff;
  }

  .content_01:hover img {
    transform: scale(1.03);
  }
}

#backTopBtn {
  z-index: 999;
}

.h1_1 {
  margin-top: 50px;
  font-size: 30px;
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px
}

.center_1 {
  font-size: 16px;
}

.p_1,
.p_2 {
  margin: 0 auto;
  font-size: 20px;
  text-align: center;
  width: 170px;
  height: 42px;
  border: 1px solid #ccc;
  line-height: 42px;
  cursor: pointer;
}

.p_1:hover,
.p_2:hover {
  color: #fff;
  background-color: #ef3e2e;
}

.home {
  position: relative;
  width: 100%;
  color: #333;

  .content {
    user-select: none;
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 99;
  }

  .header {
    width: 100%;
    margin: 0 auto;
    height: 78px;
    position: relative;
    max-width: 1920px;

    img {
      width: 150px;
      height: 100%;
      cursor: pointer;
    }

    .left {
      position: absolute;
      left: 130px;
      width: 100px;
      height: 100%;
    }

    .center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 40%;
      height: 100%;
      display: flex;

      div {
        flex: 1;
        text-align: center;
        line-height: 78px;
        cursor: pointer;
      }

      div:hover {
        color: #ef3e2e;
        width: 100%;
      }
    }

    .right {
      position: absolute;
      right: 70px;
      width: 100px;
      height: 100%;

      .lang {
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }

  .el-carousel {
    max-width: 1920px;
    margin: 0 auto;
  }



  h2 {
    max-width: 1470px;
    font-size: 1.7rem;
    margin: 50px auto;
    text-align: center;
  }

  h2::before {
    content: "";
    width: 30%;
    height: 2px;
    background-color: #c7000b;
    position: absolute;
    margin-top: 43px;
    left: 50%;
    transform: translateX(-50%);
    // top:55px;
    min-width: 35px;
    max-width: 35px;
  }

  .isShow {
    position: fixed;
    top: 78px;
    width: 100%;
    height: 500px;
    z-index: 999;
    background-color: #fff;

    .isShow_1 {
      max-width: 1470px;
      margin: 0 auto;
    }
  }
}

.test-enter-active {
  animation: slidein 0.3s linear;
}

.test-leave-active {
  animation: slidein 0.3s linear reverse;
}

@keyframes slidein {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0px);
  }
}

// 淡入淡出的效果
.fade-leave {
  opacity: 1;
}

.fade-leave-active {
  transition: all 0.2s;
}

.fade-leave-to {
  opacity: 0;
}

::v-deep .el-carousel__arrow {
  font-size: 30px;
  width: 60px;
  height: 60px;
}

.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.3;
  background-color: rgba(0, 0, 0, 0.7);

  &-show {
    animation: show 0.25s linear forwards;
  }

  &-hide {
    animation: hide 0.25s linear forwards;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

::v-deep .el-icon svg {
  margin-top: 5px;
}

@media only screen and (min-width: 767px) {
  .home {
    display: block;
  }

  ._div {
    display: none;
  }
}

// 小屏区域
@media only screen and (max-width: 767px) {
  .home {
    display: none;
  }

  ._div {
    display: block;
    font-size: 50px;

    h2 {
      text-align: center;
    }

    h2::before {
      content: "";
      width: 30%;
      height: 8px;
      background-color: #c7000b;
      position: absolute;
      margin-top: 100px;
      left: 50%;
      transform: translateX(-50%);
      // top:55px;
      min-width: 100px;
      max-width: 100px;
    }

    .activity {
      display: flex;

      .activity_1:hover .box {
        padding-left: 80px;
        transition: all 0.5s;
        color: #fff;
      }

      // .activity_1:hover img{
      //   transform: scale(1.03);
      // }
      .activity_1 {
        position: relative;
        flex: 1;
        height: auto;
        box-sizing: border-box;
        padding: 30px;
        cursor: pointer;
        overflow: hidden;

        .box {
          position: absolute;
          bottom: 41px;
          width: 91%;
          height: 250px;
          background: linear-gradient(to right, #1D469A, transparent);
          font-size: 40px;
          color: #ccc;
          line-height: 180px;
          transition: all 0.5s;
          padding-left: 30px;

          h3 {
            font-size: 55px;
          }

          .span01 {
            height: 60px;
            z-index: 9;
            margin-top: -100px;
            font-size: 38px;
          }
        }

        .box_left {
          position: absolute;
          left: 30px;
          top: 30px;
          width: 300px;
          height: 75px;
          background-color: #aaa;
          line-height: 75px;
          text-align: center;
          color: #fff;
          z-index: 3;

        }

        img {
          width: 100%;
          height: 970px;
        }

      }


    }

    .jour {
      position: relative;
      width: 97%;
      height: auto;
      background-color: #F8F8F8;
      box-sizing: border-box;
      margin: 30px;
      overflow: hidden;

      p:nth-child(2) {
        margin-top: 110px;
      }

      p:hover {
        color: #ef3e2e;
      }

      p:hover span {
        color: #ef3e2e;
      }

      p {
        font-size: 53px;
        font-weight: bold;
        color: #333;
        margin: 70px 0;
        cursor: pointer;

        span {
          font-size: 38px;
          color: #666;
          font-weight: 100;
        }
      }

      .box_left {
        position: absolute;
        left: 1px;
        top: 0px;
        width: 300px;
        height: 75px;
        background-color: #aaa;
        line-height: 75px;
        text-align: center;
        color: #fff;
        z-index: 3;

      }
    }

    .moreJour {
      display: flex;

      p {
        flex: 1;
        text-align: center;
        margin: 40px 20px;
        border: 1px solid #ccc;
        height: 100px;
        line-height: 100px;
        width: 150px;
        cursor: pointer;
        float: left;
        overflow: hidden;
      }

      p:hover {
        background-color: #ef3e2e;
        color: #fff;
      }
    }

    .move_img1 {
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 800px;
      }

      .box {
        position: absolute;
        bottom: 41px;
        width: 91%;
        height: 200px;
        background: linear-gradient(to right, #1D469A, transparent);
        font-size: 40px;
        color: #ccc;
        transition: all 0.5s;
        padding-left: 30px;

        p {
          height: 60px;
          margin: 20px 0;
        }
      }
    }

    .move_img2 {

      display: flex;
      box-sizing: border-box;
      // padding: 30px;
      height: 700px;
      cursor: pointer;
      margin-bottom: 100px;

      .box_11 {
        position: absolute;
        bottom: 0;
        width: 80%;
        height: 200px;
        background: linear-gradient(to right, #1D469A, transparent);
        font-size: 40px;
        color: #ccc;
        line-height: 180px;
        transition: all 0.5s;
        padding-left: 30px;
        overflow: hidden;

        p {

          height: 60px;
          margin-top: -30px;
        }
      }

      .Move {
        padding: 30px;
        overflow: hidden;
        position: relative;

        img {

          text-align: center;
          margin: 30px auto;
          margin-top: 30px;
          width: 99%;
          flex: 1;
        }
      }


    }

    .move_img1,
    .move_img2 {
      div {
        p {
          font-size: 50px;
        }
      }
    }
  }
}
</style>
